// import formatMessage from 'format-message';
import React from 'react';
import {FormattedMessage} from 'react-intl';

/**
 * MicroBit More extension
 */

import microbitMoreIconURL from './entry-icon.png';
import microbitMoreInsetIconURL from './inset-icon.svg';
import microbitMoreConnectionIconURL from './connection-icon.png';
import microbitMoreConnectionSmallIconURL from './connection-small-icon.png';
// import translations from './translations.json';

const version = 'v2-0.2.5';

const entry = {
    /* get name () {
        return `${formatMessage({
            defaultMessage: 'MicroBit More',
            description: 'Name of this extension',
            id: 'mbitMore.entry.name'
        })} (${version})`;
    }, */
    name: 'MMN Micro:Bit',
    extensionId: 'microbitMore',
    extensionURL: null,
    collaborator: 'NCKU MMN Lab',
    iconURL: microbitMoreIconURL,
    insetIconURL: microbitMoreInsetIconURL,
    description: (
        <FormattedMessage
            defaultMessage="使用 micro:bit 的所有功能。"
            description="Description for the 'Microbit More' extension"
            id="mbitMore.entry.description"
        />
    ),
    /* get description () {
        return formatMessage({
            defaultMessage: 'Play with all functions of micro:bit.',
            description: "Description for the 'Microbit More' extension",
            id: 'mbitMore.entry.description'
        });
    }, */
    featured: true,
    disabled: false,
    bluetoothRequired: true,
    internetConnectionRequired: false,
    launchPeripheralConnectionFlow: true,
    useAutoScan: false,
    connectionIconURL: microbitMoreConnectionIconURL,
    connectionSmallIconURL: microbitMoreConnectionSmallIconURL,
    connectingMessage: (
        <FormattedMessage
            defaultMessage="連線中..."
            description="Message to help people connect to their micro:bit."
            id="gui.extension.microbit.connectingMessage"
        />
    ),
    /* get connectingMessage () {
        return formatMessage({
            defaultMessage: 'Connecting',
            description: 'Message to help people connect to their micro:bit.',
            id: 'gui.extension.microbit.connectingMessage'
        });
    }, */
    helpLink: 'https://student.zwlab.cc/'
    // helpLink: 'https://microbit-more.github.io/'
    //translationMap: translations
};

export default entry;
